import React from 'react';
import ReactTable from "react-table";
import { 
  Segment, Dimmer, Loader, Message, Header, Icon, Button
} from 'semantic-ui-react'
import { GET } from '../..//utils/HttpClient'
import TechnicianDetailModal  from './TechnicianDetailModal'
import TechnicianRejectModal from './TechnicianRejectModal'

export default class TechnicianRegistration extends React.PureComponent{
  
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      errorMessage: '',
      isLoading: true,
      detailModalOpen: false,
      rejectModalOpen: false,
      selectedTechnician: {},
      columns: [
        {
          Header: 'ID Card',
          accessor: 'id_card'
        },
        {
          Header: 'First Name',
          accessor: 'first_name'
        },
        {
          Header: 'Last Name',
          accessor: 'last_name'
        },
        {
          Header: '',
          width: 100,
          Cell: row => (
            <Button primary onClick={() => this.handleItemClicked(row.original)}>Detail</Button>
          )
        }
      ]
    }
    this.fetchData()
  }

  fetchData = () => {
    GET('/technician/pending-registration/').then(
      (payload) => {
        this.setState({
          isLoading: false,
          rows: payload.data
        })
    },
    (payload) => {
      this.setState({
        isLoading: false,
        errorMessage: payload.errorMessages
      })
    })
  }

  renderErrorMessage = () => {
    if(this.state.errorMessage!=='') {
      return (
        <Message
          error
          header='Error! Please try re-select this tab again'
          content={this.state.errorMessage}
        />
      )
    }
    else {
      return ''
    }
  }

  handleItemClicked = (original) => {
    this.setState({
      detailModalOpen: true,
      selectedTechnician: original
    })
  }

  render() {
    return(
      <Segment>
        <Dimmer active={this.state.isLoading}>
          <Loader>Loading</Loader>
        </Dimmer>

        {this.renderErrorMessage()}

        <TechnicianDetailModal
          open={this.state.detailModalOpen}
          onClose={() => this.setState({detailModalOpen: false})}
          {...this.state.selectedTechnician}
          images={this.state.selectedTechnician.documents == null ? [] : this.state.selectedTechnician.documents.map(
            (data) => ({original: data.image, thumbnail: data.image})
          )}
          onApproved={() => {this.setState({isLoading: true}, this.fetchData)}}
          onRejected={() => { this.setState({detailModalOpen: false, rejectModalOpen: true})}}
        />

        <TechnicianRejectModal
          open={this.state.rejectModalOpen}
          onClose={() => this.setState({ rejectModalOpen: false })}
          onRejected={() => { this.setState({ isLoading: true, rejectModalOpen: false }, this.fetchData)}}
          {...this.state.selectedTechnician}
        />

        <Header as='h2'>
          <Icon name='pencil alternate' />
          <Header.Content>ใบสมัครช่าง</Header.Content>
        </Header>        
        <ReactTable
          NoDataComponent={() => null}
          data={this.state.rows}
          columns={this.state.columns}
          showPagination={false}
        />
      </Segment>
    )
  }
}