import React, { PureComponent } from 'react'
import cealect_logo from '../assets/cealect_logo.png'
import {
  Button, Form, Grid, Header, Message, Segment, Image
} from 'semantic-ui-react'
import { GET, POST } from '../utils/HttpClient'

export default class ResetPassword extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      email: '',
      password: '',
      new_password: '',
      errorMessages: null,
      isInitialized: false,
      isLoading: false,
      isSuccess: false,
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
      errorMessages: null,
    })

    GET(`/common/reset-password-detail/${this.state.id}/`)
    .then( payload => this.setState({
      email: payload.data.email,
      isInitialized: true
    }))
    .catch( payload => {
      if(payload.status === 404) {
        this.setState({
          errorMessages: 'Invalid Reset Code'
        })
      }
      else {
        this.setState({
          errorMessages: payload.errorMessages
        })
      }
    })
    .finally( () => this.setState({
      isLoading: false
    }))
  }


  onResetPassword = async (evt) => {
    evt.preventDefault();
    this.setState({
      errorMessages: null,
      isLoading: true
    })

    const params = {
      password: this.state.new_password
    }

    POST(`/common/reset-password/${this.state.id}/`, params)
    .then(payload => this.setState({
      isSuccess: true,
    }))
    .catch(payload => this.setState({
      errorMessages: payload.errorMessages
    }))
    .finally(() => this.setState({
      isLoading: false
    }))
    
  }

  onInputChanged = (evt, data) => {
    this.setState({ [data.name]: data.value });
  };

  render() {
    return (
      <div className='login-form'>
        <style>{`
          body > div,
          body > div > div,
          body > div > div > div.login-form {
            height: 100%;
          }
        `}
        </style>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as='h2' color='blue' textAlign='center'>
              <Image src={cealect_logo} /> Cealect Reset Password
            </Header>
            { this.state.isSuccess && 
              <Message
                positive
                header='Success!'
                content='Update password successfully!'
              />
            }
            { !this.state.isSuccess && this.state.errorMessages !== null && 
              <Message
                error
                content={this.state.errorMessages}
              />
            }
            { !this.state.isSuccess && this.state.isInitialized && 
              <Form size='large' onSubmit={this.onResetPassword}>
              <Segment>
                <Form.Input
                  fluid
                  name='email'
                  icon='mail'
                  iconPosition='left'
                  placeholder='Username'
                  value={this.state.email}
                  readOnly={true}
                />
                <Form.Input
                  fluid
                  name='password'
                  icon='lock'
                  iconPosition='left'
                  type='password'
                  placeholder='New Password'
                  value={this.state.password}
                  onChange={this.onInputChanged}
                />
                <Form.Input
                  fluid
                  name='new_password'
                  icon='lock'
                  iconPosition='left'
                  placeholder='Confirm Password'
                  type='password'
                  value={this.state.new_password}
                  onChange={this.onInputChanged}
                />

                <Button
                  color='blue'
                  fluid size='large'
                  disabled={
                    (!this.state.password || !this.state.new_password) || 
                    (this.state.password !== this.state.new_password) || 
                    (this.state.password.length < 3)
                  }
                  loading={this.state.isLoading}
                >
                  ResetPassword
                </Button>
              </Segment>
              </Form>
            }
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}
