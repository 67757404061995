import React from 'react';
import ReactTable from "react-table";
import moment from 'moment';
import download from "js-file-download";
import { isMobile } from 'react-device-detect';
import {
  Message, Button, Grid, Form, Label
} from 'semantic-ui-react'
import {
  DatesRangeInput
} from 'semantic-ui-calendar-react';
import { GET } from '../../utils/HttpClient'

const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';
const API_DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_PROPS = {
  rows: [],
  errorMessage: '',
  datesRange: '',
  columns: []
}

export default class SummaryReport extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = DEFAULT_PROPS;
  }

  componentDidMount() {
    this.fetchColumns()
    this.setState(DEFAULT_PROPS)
  }

  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }

  onSubmit = () => {
    const date_ranges_str = this.state.datesRange.split('-');
    if (date_ranges_str.length === 2) {
      const start_date = moment(date_ranges_str[0], DISPLAY_DATE_FORMAT)
      const end_date = moment(date_ranges_str[1], DISPLAY_DATE_FORMAT)

      this.fetchData(
        start_date.format(API_DATE_FORMAT),
        end_date.format(API_DATE_FORMAT)
      )
    }
  }

  fetchColumns = async () => {
    await GET(`${this.props.baseURL}/columns/`).then(
      (payload) => this.setState({
        columns: payload.data
      })
    ).catch(
      (payload) => this.setState({
        errorMessage: payload.errorMessages
      })
    ).finally(
      () => this.props.isLoading(false)
    )
  }

  fetchData = async (start_date, end_date) => {
    this.props.isLoading(true)
    this.setState({ errorMessage: '' })
    const params = {
      'start_date': start_date,
      'end_date': end_date
    }
    await GET(`${this.props.baseURL}`, params).then(
      (payload) => {
        this.setState({
          rows: payload.data
        })
      },
      (payload) => {
        this.setState({
          errorMessage: payload.errorMessages
        })
      }).finally(() => this.props.isLoading(false))
  }

  downloadExcel = async () => {
    const date_ranges_str = this.state.datesRange.split('-');
    if (date_ranges_str.length === 2) {
      const params = {
        'start_date': moment(date_ranges_str[0], DISPLAY_DATE_FORMAT).format(API_DATE_FORMAT),
        'end_date': moment(date_ranges_str[1], DISPLAY_DATE_FORMAT).format(API_DATE_FORMAT)
      }
      try {
        this.props.isLoading(true)
        this.setState({ errorMessage: '' })
        const response = await GET(`${this.props.baseURL}/excel/`, params, false, true);
        if(!isMobile) {
          // PC download
          download(response.data, `${this.props.reportName}_${params.start_date}_${params.end_date}.xlsx`);
        }
        else {
          // Mobile download
          let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          let downloadLink = document.createElement("a");
          let downloadUrl = URL.createObjectURL(blob);
          downloadLink.target = '_blank';
          downloadLink.href = downloadUrl;
          downloadLink.download = `${this.props.reportName}_${params.start_date}_${params.end_date}.xlsx`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } catch (error) {
        this.setState({ errorMessage: error.errorMessages })
      } finally {
        this.props.isLoading(false)
      }
    }
  }

  renderErrorMessage = () => {
    if (this.state.errorMessage !== '') {
      return (
        <Message
          error
          header='Error!'
          content={this.state.errorMessage}
        />
      )
    }
    else {
      return null
    }
  }

  renderExcelButton = () => {
    if (this.state.rows.length > 0) {
      return (
        <Button
          color='teal'
          onClick={this.downloadExcel}
        >Export Excel</Button>
      )
    }
    else {
      return null;
    }
  }

  render() {
    return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={10}>
            <Form onSubmit={this.onSubmit}>
              <Form.Group>
                <Form.Field>
                  <Label>{this.props.filterLabel}</Label>
                </Form.Field>
                <Form.Field width={5}>
                  <DatesRangeInput
                    name="datesRange"
                    placeholder="From - To"
                    dateFormat={DISPLAY_DATE_FORMAT}
                    value={this.state.datesRange}
                    iconPosition="left"
                    onChange={this.handleChange}
                    autoComplete="off"
                  />
                </Form.Field>
                <Form.Button type='submit' primary>ค้นหา</Form.Button>
                {this.renderExcelButton()}
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column>
            {this.renderErrorMessage()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ReactTable
              NoDataComponent={() => null}
              data={this.state.rows}
              columns={this.state.columns}
              showPagination={true}
              defaultPageSize={10}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}