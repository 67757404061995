import React from 'react';
import default_profile from '../../assets/default_profile.png'
import { 
  Modal, Image, List, Button, Message
} from 'semantic-ui-react';
import ImageGallery from 'react-image-gallery';
import { POST } from '../../utils/HttpClient';

export default class TechnicianDetailModal extends React.PureComponent{

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errors: null
    }
  }

  getProfilePicture = () => {
    if(this.props.profile_picture) {
      return this.props.profile_picture
    }
    else {
      return default_profile
    }
  }

  getRoles = () => {
    let roles = []
    if(this.props.roles) {
      this.props.roles.forEach(function(role) {
        roles.push(role.name)
      })
    }
    return roles.join(', ')
  }

  handleApproveClicked = () => {
    const params = {
      'action': 'approved',
      'user_id': this.props.id,
      'description': ''
    }

    this.setState({
        isLoading: true,
        errorMessage: ''
    }, () => {
      POST('/technician/change-technician-state/', params)
      .then(() => {
        this.setState({errors: null})
        this.props.onApproved();
      })
      .catch(payload => this.setState({errors: payload.errorMessages}))
      .finally(()=> this.setState({isLoading: false}))
    })
  }

  handleRejectClicked = () => {
    this.props.onRejected();
  }

  render() {
    return(
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>รายละเอียดช่าง ({this.props.technician_type})</Modal.Header>
        <Modal.Content image>
          <Image wrapped src={this.getProfilePicture()}/>
          <Modal.Description>
            <List size='large'>
              <List.Item>
                <List.Icon name='credit card'/>
                <List.Content>{this.props.id_card}</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='user circle'/>
                <List.Content>{this.props.first_name} {this.props.last_name}</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='mail'/>
                <List.Content>{this.props.email}</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='phone'/>
                <List.Content>{
                  this.props.tel_num ? this.props.tel_num.replace('+66', '0') : '-'
                }</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='dollar'/>
                <List.Content>{this.props.bank} - {this.props.bank_account}</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='users'/>
                <List.Content>{this.getRoles()}</List.Content>
              </List.Item>
            </List>
            {this.props.images.length > 0 && <ImageGallery 
              items={this.props.images} 
              showPlayButton={false}
            />}

          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          { this.state.errors && <Message error content={this.state.errors}/>}
          {this.props.showDetail && (
            <Button color='teal' 
              loading={this.state.isLoading} 
              onClick={() => {
                window.open(`/admin/app_technician/technician/${this.props.technician_id}/change/`, '_blank');
              }}>
              Admin Detail
            </Button>
          )}
          {this.props.showReject && (
            <Button color='red' onClick={this.handleRejectClicked} loading={this.state.isLoading}>
              Reject
            </Button>
          )}
          <Button primary onClick={this.handleApproveClicked} loading={this.state.isLoading}>
            Approve
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }  
}

TechnicianDetailModal.defaultProps = {
  open: false,
  showReject: true,
  showDetail: true,
  onClose: ()=> {},
  id: 0,
  profile_picture: '',
  id_card: '',
  first_name: '',
  last_name: '',
  email: '',
  tel_num: '',
  technician_type: '',
  roles: [],
  bank: '',
  bank_account: '',
  images: [],

  onApproved: () => {},
  onRejected: () => {},
}
