import React from 'react';
import { 
  Modal, Button, Message, Form
} from 'semantic-ui-react';
import { POST } from '../../utils/HttpClient';
import { Formik } from "formik";
import * as yup from "yup";

export default class JobSummaryRejectModal extends React.PureComponent{

  rejectFormSubmit=null

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errors: null,
    }

  }

  handleReject = (values) => {
    const params = {
      'action': 'reject',
      'id': this.props.id,
      'description': values.rejectCause
    }

    this.setState({
      isLoading: true,
      errorMessage: ''
    }, () => {
      POST('/common/job-summary-response/', params)
      .then(() => {
        this.setState({errors: null})
        this.props.onRejected();
      })
      .catch(payload => this.setState({errors: payload.errorMessages}))
      .finally(()=> {this.setState({isLoading: false})})
    })
  }

  formikValidationSchema = () => {
    let schema = yup.object().shape({
      reject_validation: yup
        .string()
        .test(
          'reject_validation',
          'Required',
          function (item) {
            return this.parent.rejectCause !== undefined && this.parent.rejectCause !== ''
          }
        ),
    });
    return schema;
  }

  render() {
    return(
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>ยืนยัน Reject รายงานจบงาน</Modal.Header>
        <Modal.Content>
          <Formik
            validateOnChange={false}
            initialValues={{...this.props}}
            validationSchema={this.formikValidationSchema}
            onSubmit={this.handleReject}
          >
            {(formikProps) => {
              const {
                submitForm,
                values,
                handleChange, 
                errors
              } = formikProps;
              this.rejectFormSubmit=submitForm; // binding for remote submit form
              return <Form>
                <Form.TextArea
                  name='rejectCause'
                  label='เหตุผลการ reject'
                  onChange={handleChange}
                  value={values.rejectCause}
                  error={errors.reject_validation}
                />
              </Form>
            }}
            </Formik>
        </Modal.Content>
        <Modal.Actions>
          { this.state.errors && <Message error content={this.state.errors}/>}
          <Button color='red' onClick={()=>this.rejectFormSubmit()} loading={this.state.isLoading}>
            Reject
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }  
}

JobSummaryRejectModal.defaultProps = {
  open: false,
  onClose: ()=> {},
  onRejected: () => {},

  id: 0,
  rejectCause: '',
}
