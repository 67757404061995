import React, { PureComponent } from 'react';
import cealect_logo from '../assets/cealect_logo.png'
import { 
  Menu, Button, Icon, Image, Confirm
} from 'semantic-ui-react'
import { GET } from '../utils/HttpClient'
import AdminPage from '../components/AdminPage'
import TechnicianRegistration from '../components/registration/TechnicianRegistration'
import ApprovePayment from '../components/payment/ApprovePayment'
import ApproveUpdateProfile from '../components/updateProfile/ApproveUpdateProfile'
import JobSummaryList from '../components/jobSummary/JobSummaryList'
import TaxInvoiceList from '../components/taxInvoice/TaxInvoiceList';
import ReportMenu from '../components/report/ReportMenu';


export default class HomeView extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      activeItem: 'techRegistration',
      showLogoutModal: false,
    };
  }

  onLogout = () => {
    GET('/sign-out')
    localStorage.removeItem('token')
    this.props.history.push("/login")
  }

  handleItemClick = (e, {id}) => { this.setState({ activeItem: id }) }
  
  renderContent = () => {
    switch(this.state.activeItem) {
      case 'admin':
        return <AdminPage/>
      case 'techRegistration':
        return <TechnicianRegistration />
      case 'approvePayment':
        return <ApprovePayment/>
      case 'approveProfile':
        return <ApproveUpdateProfile/>
      case 'jobSummary':
        return <JobSummaryList/>
      case 'taxInvoice':
        return <TaxInvoiceList/>
      case 'report':
        return <ReportMenu/>
      default:
        return <TechnicianRegistration/>
    }
  }

  render() {
    return (
      <div>
        <Menu attached='top' stackable icon='labeled' >
          <Menu.Item>
            <Image src={cealect_logo} size='mini' alt='Cealect Management System'/>
          </Menu.Item>
          <Menu.Item 
            id='admin'
            name='Administrator' 
            active={this.state.activeItem === 'admin'} 
            onClick={this.handleItemClick} 
          >
            <Icon name='settings' />
            Administrator
          </Menu.Item>
          <Menu.Item 
            id='techRegistration'
            name='ใบสมัครช่าง'
            active={this.state.activeItem === 'techRegistration'} 
            onClick={this.handleItemClick} 
          >
            <Icon name='user plus'/>
            ใบสมัครช่าง
          </Menu.Item>
          <Menu.Item
            id='approveProfile'
            name='ตรวจสอบการเปลี่ยน Profile'
            active={this.state.activeItem === 'approveProfile'}
            onClick={this.handleItemClick}
          >
            <Icon name='edit' />
            ตรวจสอบการเปลี่ยน Profile
          </Menu.Item>
          <Menu.Item 
            id='approvePayment'
            name='ยืนยันการชำระเงิน'
            active={this.state.activeItem === 'approvePayment'} 
            onClick={this.handleItemClick} 
          >
            <Icon name='usd'/>
            ยืนยันการชำระเงิน
          </Menu.Item>
          <Menu.Item 
            id='taxInvoice'
            name='คำร้องขอใบกำกับภาษี'
            active={this.state.activeItem === 'taxInvoice'} 
            onClick={this.handleItemClick} 
          >
            <Icon name='envelope'/>
            คำร้องขอใบกำกับภาษี
          </Menu.Item>
          <Menu.Item
            id='jobSummary'
            name='สรุปรายงานจบงาน'
            active={this.state.activeItem === 'jobSummary'}
            onClick={this.handleItemClick}
          >
            <Icon name='file alternate' />
            สรุปรายงานจบงาน
          </Menu.Item>
          <Menu.Item
            id='report'
            name='รายงาน'
            active={this.state.activeItem === 'report'}
            onClick={this.handleItemClick}
          >
            <Icon name='chart line' />
            รายงาน
          </Menu.Item>
          <Menu.Item position='right'>
            <Button color='red' onClick={ () => {this.setState({showLogoutModal: true})} }>
              <Icon name='shutdown'/>Logout
            </Button>
            <Confirm
              open={this.state.showLogoutModal}
              header='Are you sure to logout ?'
              confirmButton='Logout'
              onCancel={ () => {this.setState({showLogoutModal: false})} }
              onConfirm={this.onLogout}
            />
          </Menu.Item>
        </Menu>
        <div>
          {this.renderContent()}
        </div>
      </div>
    )
  }
}