import React from 'react';
import {
  Header, Icon
} from 'semantic-ui-react'

export default class AdminPage extends React.PureComponent {

  render() {
    return (
      <Header as='h2' icon textAlign='center' style={{cursor:'pointer'}} onClick={() => { window.open("/admin"); }}>
        <Icon name='user secret' circular />
        <Header.Content>Click for Admin Page</Header.Content>
      </Header>
    )
  }
}