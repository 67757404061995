import { GET } from '../utils/HttpClient'
import { toast } from 'react-semantic-toasts';

export function randomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

/**
 * Load choices from server and create options for dropdown component
 * {key:..., text:..., value:....}
 * @param {React.PureComponent} component React component for set the state variable
 * @param {string} url URL to load data
 * @param {string} optionVariable name of the options variable declared on the state
 * @param {boolean} showEngName Boolean to show the english name or only thai name
 * @param {boolean} idKey Boolean to use id as key or name
 */
export function dropDownLoader(component, url, optionVariable, showEngName=true, idValue=true) {
  GET(url).then(
    (payload) => {
      component.setState({
        [optionVariable]: payload.data.map(
          (data) => {
            let name = ''
            if('name' in data) {
              name = data.name
            }
            else {
              name = showEngName ? `${data.name_th} (${data.name_en})` : data.name_th
            }
            return {
              key: data.id,
              text: name,
              value: idValue ? data.id : name,
            }
          }
        )
      })
    }
  )
}

/**
 * Auto select all the input element when clicked.
 * Tend to use with input e.g. textbox, select
 * @param {*} e click event
 */
export function selectAllOnFocus(e) {
  e.target.select();
}

/**
 * Replace all the given object replacedValue
 * @param {*} obj 
 * @param {*} value target value
 * @param {*} replacedValue value to be replaced
 */
export function replaceValue(obj, value, replacedValue) {
  let newObj = {}
  for(let key in obj) {
    if(obj[key] === value) {
      newObj[key] = replacedValue
    } else {
      newObj[key] = obj[key]
    }
  }
  return newObj;
}

/**
 * Format number in bytes into KB, MB, GB...
 * @param {*} bytes 
 * @param {*} decimals 
 */
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Remove null, undefined, empty string from object 
 * and create new instance of this object
 * @param {*} obj Object to be removed
 */
export function removeEmpty (obj) {
  const newObj = {};

  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === "object") {
      newObj[key] = removeEmpty(obj[key]); // recurse
    } else if (obj[key] !== null && obj[key] !== '') {
      newObj[key] = obj[key]; // copy value
    }
  });

  return newObj;
};


/** 
 * Util function for display simple toast message.
 * Don't forget to
 * import { SemanticToastContainer } from 'react-semantic-toasts';
 * And add <SemanticToastContainer/> component into your view
 */
export function showToastMessage (title, message, isSuccess=true) {
  toast(
    {
      title: title,
      description: message,
      icon: isSuccess ? 'check' : 'exclamation',
      color: isSuccess ? 'green' : 'red'
    }
  );
}
