import React from 'react';
import { SemanticToastContainer } from 'react-semantic-toasts';
import {
  Menu, Dimmer, Loader
} from 'semantic-ui-react'
import SummaryReport from './SummaryReport';

const REPORT_TECHNICIAN_SUMMRY = 'technicianSummaryReport'
const REPORT_CUSTOMER_SUMMARY = 'customerSummaryReport'
const REPORT_SERVICE_SUMMARY = 'serviceSummaryReport'

export default class ReportMenu extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      activeItem: REPORT_TECHNICIAN_SUMMRY,
      isLoading: false,
    }
  }

  handleItemClick = (e, { id }) => { this.setState({ activeItem: id }) }
  handleLoading = (loading) => { this.setState({ isLoading: loading})}

  renderContent = () => {
    switch (this.state.activeItem) {
      case REPORT_TECHNICIAN_SUMMRY:
        return (
          <SummaryReport
            key={REPORT_TECHNICIAN_SUMMRY}
            isLoading={this.handleLoading}
            baseURL='/reports/technician-summary'
            reportName='technician_summary'
            filterLabel='ช่วงวันที่ลงทะเบียน'
          />
        )
      case REPORT_CUSTOMER_SUMMARY:
        return (
          <SummaryReport
            key={REPORT_CUSTOMER_SUMMARY}
            isLoading={this.handleLoading}
            baseURL='/reports/customer-summary'
            reportName='customer_summary'
            filterLabel='ช่วงวันที่ลงทะเบียน'
          />
        )
      case REPORT_SERVICE_SUMMARY:
        return (
          <SummaryReport
            key={REPORT_SERVICE_SUMMARY}
            isLoading={this.handleLoading}
            baseURL='/reports/service-summary'
            reportName='service_summary'
            filterLabel='ช่วงวันที่เริ่มงาน'
          />
        )
      default:
        return <div></div>
    }
  }

  render() {
    return (
      <div>
        <Dimmer active={this.state.isLoading}>
          <Loader>Loading</Loader>
        </Dimmer>
        <Menu pointing secondary size='massive'>
          <Menu.Item
            id={REPORT_TECHNICIAN_SUMMRY}
            name='สรุปช่าง'
            active={this.state.activeItem === REPORT_TECHNICIAN_SUMMRY}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            id={REPORT_CUSTOMER_SUMMARY}
            name='สรุปลูกค้า'
            active={this.state.activeItem === REPORT_CUSTOMER_SUMMARY}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            id={REPORT_SERVICE_SUMMARY}
            name='สรุปบริการ'
            active={this.state.activeItem === REPORT_SERVICE_SUMMARY}
            onClick={this.handleItemClick}
          />
        </Menu>
        <SemanticToastContainer />
        {this.renderContent()}
      </div>
    )
  }
}