import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeView from "./views/HomeView";
import PrivateRoute from "./routes/PrivateRoute";
import LoginView from "./views/LoginView";
import ResetPasswordView from "./views/ResetPasswordView";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Switch>
            <Route path="/login" component={LoginView} />
            <Route path="/reset-password/:id" component={ResetPasswordView} />
            <Route path="/reset-password/" component={ResetPasswordView} />
            <PrivateRoute path="/" component={HomeView} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
