import React, { PureComponent } from 'react'
import cealect_logo from '../assets/cealect_logo.png'
import { 
  Button, Form, Grid, Header, Message, Segment, Image
} from 'semantic-ui-react'
import { POST } from '../utils/HttpClient'

export default class LoginView extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      username: localStorage.getItem('username') ? localStorage.getItem('username') : '',
      password: '',
      errorMessage: '',
      isLoading: false,
    };
  }


  onLogin = (evt) => {  
    evt.preventDefault();
    this.setState({
      errorMessage: '',
      isLoading: true
    }, this.doLogin)
  }

  doLogin = () => {
    const params = {
      email: this.state.username,
      password: this.state.password
    };

    POST('/api-token-auth/', params).then(
      (payload) => {
        // Authen Success with payload parameter
        localStorage.setItem('username', this.state.username)
        localStorage.setItem('token', payload.data.token)
        this.props.history.push("/") // Redirect to home page with react-route
      },
      (payload) => {
        // Authen Failed with payload parameter
        this.setState({
          errorMessage: payload.errorMessages,
          isLoading: false
        })
      }
    )
  }

  onInputChanged = (evt, data) => {
    this.setState({ [data.name]: data.value });
  };

  render() {
    return (
      <div className='login-form'>
        <style>{`
          body > div,
          body > div > div,
          body > div > div > div.login-form {
            height: 100%;
          }
        `}
        </style>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as='h2' color='blue' textAlign='center'>
              <Image src={cealect_logo}/> Cealect Management System
            </Header>
            <Form size='large' onSubmit={this.onLogin}>
              <Segment>
                <Message
                  error
                  header='Authentication Failed!'
                  content={this.state.errorMessage}
                  visible={this.state.errorMessage!==''}
                />
                <Form.Input 
                  fluid 
                  name='username'
                  icon='user' 
                  iconPosition='left' 
                  placeholder='Username' 
                  value={this.state.username}
                  onChange={this.onInputChanged}
                />
                <Form.Input
                  fluid
                  name='password'
                  icon='lock'
                  iconPosition='left'
                  placeholder='Password'
                  type='password'
                  value={this.state.password}
                  onChange={this.onInputChanged}
                />

                <Button 
                  color='blue' 
                  fluid size='large'
                  disabled={ 
                    !this.state.username  || !this.state.password
                  }
                  loading={this.state.isLoading}
                >
                  Login
                </Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}
