import React from "react";
import { Route, Redirect } from "react-router-dom";

class PrivateRoute extends React.Component {
  renderComponent = props => {
    const { component: Component } = this.props;

    if (localStorage.getItem('token') !== null) {
      return <Component {...props} />
    }
    else {
      return (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  };

  render() {
    const { component, ...rest } = this.props;
    return <Route {...rest} render={this.renderComponent} />;
  }
}

export default PrivateRoute;
