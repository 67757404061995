import React from 'react';
import { 
  Modal, List, Button, Message
} from 'semantic-ui-react';
import ImageGallery from 'react-image-gallery';
import { POST } from '../../utils/HttpClient';

export default class JobSummaryDetail extends React.PureComponent{

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errors: null
    }
  }

  handleApproveClicked = async () => {
    this.setState({
        isLoading: true,
        errorMessage: ''
    })

    const params = {
        'id': this.props.id,
        'action': 'accept',
    }
    try {
        await POST('/common/job-summary-response/', params).then(
          (payload) => {
            this.setState({
              isLoading: false
            })
            this.props.onClose()
          }
        )
    } catch(payload) {
        this.setState({
            errors: payload.errorMessages,
            isLoading: false
        })
    }
  }

  handleRejectClicked = async () => {
    this.props.onRejected()
  }

  render() {
    return(
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>รายละเอียดรายงานการจบงาน</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <List size='large'>
              <List.Item>
                <List.Header>Service</List.Header>
                <List.Content>{this.props.service}</List.Content>
              </List.Item>
              <List.Item>
                <List.Header>Customer</List.Header>
                <List.Content>{this.props.customer_name}</List.Content>
              </List.Item>
              <List.Item>
                <List.Header>Technician</List.Header>
                <List.Content>{this.props.technician_name}</List.Content>
              </List.Item>
              <List.Item>
                <List.Header>รายละเอียดการดำเนินการ</List.Header>
                <List.Content>{this.props.summary}</List.Content>
              </List.Item>
              <List.Item>
                <List.Header>หมายเหตุ</List.Header>
                <List.Content>{this.props.memo}</List.Content>
              </List.Item>
            </List>
            {this.props.images.length > 0 && <ImageGallery 
              items={this.props.images} 
              showPlayButton={false}
            />}

          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          { this.state.errors && <Message error content={this.state.errors}/>}
          <Button color='red' onClick={this.handleRejectClicked} loading={this.state.isLoading}>
            Reject
          </Button>
          <Button primary onClick={this.handleApproveClicked} loading={this.state.isLoading}>
            Approve
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }  
}

JobSummaryDetail.defaultProps = {
  open: false,
  onClose: ()=> {},
  id: 0,
  images: [],
  customer_email: '',
  customer_name: '',
  technician_email: '',
  technician_name: '',
  service: '',
  summary: '',
  memo: '',
  onApproved: () => {},
  onRejected: () => {},
}
