import React from 'react';
import ReactTable from "react-table";
import {
  Segment, Dimmer, Loader, Message, Header, Icon, Button
} from 'semantic-ui-react'
import { GET } from '../..//utils/HttpClient'
import TechnicianDetailModal from '../registration/TechnicianDetailModal'

export default class ApproveUpdateProfile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      errorMessage: '',
      isLoading: false,
      detailModalOpen: false,
      selectedTechnician: {},
      columns: [
        {
          Header: 'ID Card',
          accessor: 'id_card'
        },
        {
          Header: 'First Name',
          accessor: 'first_name'
        },
        {
          Header: 'Last Name',
          accessor: 'last_name'
        },
        {
          Header: '',
          width: 100,
          Cell: row => (
            <Button primary onClick={() => this.handleItemClicked(row.original)}>Detail</Button>
          )
        }
      ]
    }
  }

  async componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ 
      isLoading: true,
      detailModalOpen: false
    });
    try {
      const profile = await GET('/technician/pending-update-profile/');
      this.setState({
        rows: profile.data
      })
    } catch (payload) {
      this.setState({ error: payload.errorMessages });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleItemClicked = (original) => {
    this.setState({
      detailModalOpen: true,
      selectedTechnician: original
    })
  }

  render() {
    return (
      <Segment>
        <Dimmer active={this.state.isLoading}>
          <Loader>Loading</Loader>
        </Dimmer>

        <TechnicianDetailModal
          showReject={false}
          open={this.state.detailModalOpen}
          onClose={() => this.setState({ detailModalOpen: false })}
          {...this.state.selectedTechnician}
          images={this.state.selectedTechnician.documents == null ? [] : this.state.selectedTechnician.documents.map(
            (data) => ({ original: data.image, thumbnail: data.image })
          )}
          onApproved={() => { this.fetchData() }}
        />

        {this.state.error && <Message error content={this.state.error} />}
        <Header as='h2'>
          <Icon name='pencil alternate' />
          <Header.Content>ตรวจสอบการเปลี่ยน Profile ของช่าง</Header.Content>
        </Header>
        <ReactTable
          NoDataComponent={() => null}
          data={this.state.rows}
          columns={this.state.columns}
          showPagination={false}
        />
      </Segment>
    )
  }
}
