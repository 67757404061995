import React from 'react';
import ReactTable from "react-table";
import { 
  Segment, Dimmer, Loader, Message, Header, Icon, Button
} from 'semantic-ui-react'
import { GET } from '../../utils/HttpClient'
import ApprovePaymentDetailModal from './ApprovePaymentDetailModal'

export default class ApprovePayment extends React.PureComponent{

  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      errorMessage: '',
      isLoading: true,
      modalOpen: false,
      selectedPayment: {},
      columns: [
        {
          Header: 'ID',
          width: 50,
          accessor: 'id'
        },
        {
          Header: 'Time',
          width: 150,
          accessor: 'created'
        },
        {
          Header: 'Amount',
          width: 150,
          accessor: 'amount'
        },
        {
          id:'customer',
          Header: 'Customer',
          width: 250,
          accessor: row => `${row.customer.first_name} ${row.customer.last_name}`,
        },
        {
          id: 'technician',
          Header: 'Technician',
          width: 250,
          accessor: row => `${row.technician.first_name} ${row.technician.last_name}`,
        },
        {
          Header: 'Service',
          accessor: 'service'
        },
        {
          Header: '',
          width: 100,
          Cell: row => (
            <Button primary onClick={() => this.handleItemClicked(row.original)}>Detail</Button>
          )
        }
      ]
    }
    this.fetchData()
  }

  fetchData = () => {
    GET('/common/payment-pending').then(
      (payload) => {
        this.setState({
          isLoading: false,
          rows: payload.data
        })
    },
    (payload) => {
      this.setState({
        isLoading: false,
        errorMessage: payload.errorMessages
      })
    })
  }

  handleItemClicked = (original) => {
    console.log(original)
    this.setState({
      modalOpen: true,
      selectedPayment: original
    })
  }

  handleModalClose = () => {
    this.setState({
      modalOpen: false,
      isLoading: true
    }, this.fetchData)
  }

  renderErrorMessage = () => {
    if(this.state.errorMessage!=='') {
      return (
        <Message
          error
          header='Error! Please try re-select this tab again'
          content={this.state.errorMessage}
        />
      )
    }
    else {
      return ''
    }
  }

  render() {
    return(
      <Segment>
        {this.renderErrorMessage()}
        <Dimmer active={this.state.isLoading}>
          <Loader>Loading</Loader>
        </Dimmer>
        <ApprovePaymentDetailModal
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          payment={this.state.selectedPayment}
        />
        <Header as='h2'>
          <Icon name='usd' />
          <Header.Content>ยืนยันการชำระเงิน</Header.Content>
        </Header>        
        <ReactTable
          NoDataComponent={() => null}
          data={this.state.rows}
          columns={this.state.columns}
          showPagination={false}
        />
      </Segment>
    )
  }
}