import React from 'react';
import { 
  Modal, Button, Message, Form
} from 'semantic-ui-react';
import { POST } from '../../utils/HttpClient';
import { dropDownLoader, selectAllOnFocus } from '../../utils/Util';
import { Formik } from "formik";
import * as yup from "yup";

export default class TechnicianRejectModal extends React.PureComponent{

  rejectFormSubmit=null

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errors: null,
      rejectCauseOptions: []
    }

    dropDownLoader(this, '/common/register-reject-cause/', 'rejectCauseOptions', true, false)
  }

  handleReject = (values) => {
    const params = {
      'action': 'rejected',
      'user_id': this.props.id,
      'description': ''
    }

    const desc = []

    values.rejectCauses.forEach((cause) => {
      desc.push(`${cause}`)
    })

    if (values.otherRejectCause !== '') {
      desc.push(`${values.otherRejectCause}`)
    }

    params['description'] = desc.join('|')

    this.setState({
      isLoading: true,
      errorMessage: ''
    }, () => {
      POST('/technician/change-technician-state/', params)
      .then(() => {
        this.setState({errors: null})
        this.props.onRejected();
      })
      .catch(payload => this.setState({errors: payload.errorMessages}))
      .finally(()=> {this.setState({isLoading: false})})
    })
  }

  formikValidationSchema = () => {
    let schema = yup.object().shape({
      reject_validation: yup
        .string()
        .test(
          'reject_validation',
          'Required',
          function (item) {
            return this.parent.rejectCauses.length !== 0 || 
            (this.parent.otherRejectCause !== undefined && this.parent.otherRejectCause !== '')
          }
        ),
    });
    return schema;
  }

  render() {
    return(
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>ยืนยัน Reject ช่าง - {this.props.first_name} {this.props.last_name}</Modal.Header>
        <Modal.Content>
          <Formik
            validateOnChange={false}
            initialValues={{...this.props}}
            validationSchema={this.formikValidationSchema}
            onSubmit={this.handleReject}
          >
            {(formikProps) => {
              const {
                submitForm,
                values,
                handleChange,
                setFieldValue,
                errors
              } = formikProps;
              this.rejectFormSubmit=submitForm; // binding for remote submit form
              return <Form>
                <Form.Dropdown
                  name='rejectCauses'
                  label='เหตุผลการ reject'
                  placeholder='กรุณาเลือก'
                  required
                  clearable
                  search
                  selection
                  multiple
                  options={this.state.rejectCauseOptions}
                  error={errors.reject_validation}
                  value={values.rejectCauses}
                  onChange={(e, data) => {
                    setFieldValue(data.name, data.value)
                  }}
                  fluid
                />
                <Form.TextArea
                  name='otherRejectCause'
                  label='เหตุผลอื่นๆ'
                  onChange={handleChange}
                  onFocus={selectAllOnFocus}
                  value={values.otherRejectCause}
                  error={errors.reject_validation}
                />
              </Form>
            }}
            </Formik>
        </Modal.Content>
        <Modal.Actions>
          { this.state.errors && <Message error content={this.state.errors}/>}
          <Button color='red' onClick={()=>this.rejectFormSubmit()} loading={this.state.isLoading}>
            Reject
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }  
}

TechnicianRejectModal.defaultProps = {
  open: false,
  onClose: ()=> {},
  onRejected: () => {},

  id: 0,
  first_name: '',
  last_name: '',
  rejectCauses: [],
  otherRejectCause: '',
}
