import React from 'react';
import ReactTable from "react-table";
import { 
  Segment, Dimmer, Loader, Message, Header, Icon, Button
} from 'semantic-ui-react'
import { GET } from '../../utils/HttpClient'
import JobSummaryDetail from './JobSummaryDetail'
import JobSummaryRejectModal from './JobSummaryRejectModal'

export default class JobSummaryList extends React.PureComponent{
  
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      errorMessage: '',
      isLoading: true,
      detailModalOpen: false, 
      rejectModalOpen: false,
      selectedItem: {},
      columns: [
        {
          Header: 'Service',
          accessor: 'service'
        },
        {
          Header: 'Customer',
          accessor: 'customer_name'
        },
          {
            Header: 'Technician',
            accessor: 'technician_name'
          },
        {
          Header: '',
          width: 100,
          Cell: row => (
            <Button primary onClick={() => this.handleItemClicked(row.original)}>Detail</Button>
          )
        }
      ]
    }
    this.fetchData()
  }

  fetchData = () => {
    GET('/common/job-summary-list/').then(
      (payload) => {
        this.setState({
          isLoading: false,
          rows: payload.data
        })
    },
    (payload) => {
      this.setState({
        isLoading: false,
        errorMessage: payload.errorMessages
      })
    })
  }

  renderErrorMessage = () => {
    if(this.state.errorMessage!=='') {
      return (
        <Message
          error
          header='Error! Please try re-select this tab again'
          content={this.state.errorMessage}
        />
      )
    }
    else {
      return ''
    }
  }

  handleItemClicked = (original) => {
    this.setState({
      detailModalOpen: true,
      selectedItem: original
    })
  }

  render() {
    return(
      <Segment>
        <Dimmer active={this.state.isLoading}>
          <Loader>Loading</Loader>
        </Dimmer>

        {this.renderErrorMessage()}

        <JobSummaryDetail
            open={this.state.detailModalOpen}
            onClose={
              () => {
                this.setState({detailModalOpen: false});
                this.fetchData();
              }
            }
            {...this.state.selectedItem}
            images={this.state.selectedItem.images == null ? [] : this.state.selectedItem.images.map(
              (data) => ({original: data.image, thumbnail: data.image})
            )}
            onApproved={() => {this.setState({isLoading: true, detailModalOpen: false}, this.fetchData)}}
            onRejected={() => {this.setState({rejectModalOpen: true, detailModalOpen: false})}}
        ></JobSummaryDetail>
        <JobSummaryRejectModal
            open={this.state.rejectModalOpen}
            onClose={() => this.setState({ rejectModalOpen: false })}
            onRejected={() => { this.setState({ isLoading: true, rejectModalOpen: false }, this.fetchData)}}
            {...this.state.selectedItem}
        ></JobSummaryRejectModal>
        <Header as='h2'>
          <Icon name='file alternate' />
          <Header.Content>สรุปรายงานจบงาน</Header.Content>
        </Header>        
        <ReactTable
          NoDataComponent={() => null}
          data={this.state.rows}
          columns={this.state.columns}
          showPagination={false}
        />
      </Segment>
    )
  }
}