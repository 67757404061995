import React from 'react';
import ReactTable from "react-table";
import { SemanticToastContainer } from 'react-semantic-toasts';
import { 
  Segment, Dimmer, Loader, Message, Header, Icon, Button, Confirm
} from 'semantic-ui-react'
import { GET } from '../../utils/HttpClient'
import { showToastMessage } from '../../utils/Util'

export default class TaxInvoiceList extends React.PureComponent{
  
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      errorMessage: '',
      isLoading: true,
      selectedItem: {},
      confirmOpen: false,
      selectedId: null,
      columns: [
        {
          Header: 'วัน/เวลา',
          accessor: 'created'
        },
        {
          Header: 'Service',
          accessor: 'service'
        },
        {
          Header: 'เลขที่ผู้เสียภาษี',
          accessor: 'tax_no'
        },
        {
          Header: 'ชื่อผู้เสียภาษี',
          accessor: 'tax_name'
        },
        {
          Header: 'ที่อยู่จัดส่งเอกสาร',
          accessor: 'mailing_address'
        },
        {
          Header: 'พิมพ์เอกสาร',
          width: 100,
          Cell: row => (
            <Button color="green" onClick={() => this.handlePrintClicked(row.original)}>Print</Button>
          )
        },
        {
          Header: 'เสร็จสิ้น',
          width: 100,
          Cell: row => (
            <Button primary onClick={() => this.handleFinishClicked(row.original)}>Done</Button>
          )
        }
      ]
    }
    this.fetchData()
  }

  fetchData = () => {
    GET('/common/tax-invoice/?acknowledge=false').then(
      (payload) => {
        this.setState({
          isLoading: false,
          rows: payload.data
        })
    },
    (payload) => {
      this.setState({
        isLoading: false,
        errorMessage: payload.errorMessages
      })
    })
  }

  renderErrorMessage = () => {
    if(this.state.errorMessage!=='') {
      return (
        <Message
          error
          header='Error!'
          content={this.state.errorMessage}
        />
      )
    }
    else {
      return ''
    }
  }

  handlePrintClicked = (original) => {
    window.open(`/common/generate-tax-invoice-pdf/${original.id}/`, '_blank')
  }

  handleFinishClicked = (original) => {
    this.setState({
      selectedId: original.id,
      confirmOpen: true
    })
  }

  onCancel = () => {
    this.setState({
      selectedId: null,
      confirmOpen: false
    })
  }

  onConfirm = async () => {
    this.setState({isLoading: true, errorMessages: ''})
    await GET(`/common/acknowledge-tax-invoice/${this.state.selectedId}/`)
    .then( payload => {
      showToastMessage('Success', '')
    })
    .catch( payload => {
      this.setState({
        errorMessage: payload.errorMessages
      })
    })
    .finally( () => {
      this.setState({
        selectedId: null, 
        confirmOpen: false, 
        isLoading: false,
        rows: []
      })
      this.fetchData()
    })
  }

  render() {
    return(
      <Segment>
        <Dimmer active={this.state.isLoading}>
          <Loader>Loading</Loader>
        </Dimmer>

        {this.renderErrorMessage()}

        <Header as='h2'>
          <Icon name='envelope' />
          <Header.Content>คำร้องขอใบกำกับภาษี</Header.Content>
        </Header>        
        <SemanticToastContainer/>
        <ReactTable
          NoDataComponent={() => null}
          data={this.state.rows}
          columns={this.state.columns}
          showPagination={false}
        />
        <Confirm
          open={this.state.confirmOpen}
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}
        />
      </Segment>
    )
  }
}