import React from 'react';
import PropTypes from 'prop-types';
import { 
  Modal, Image, List, Button, Message
} from 'semantic-ui-react'
import { GET } from '../../utils/HttpClient'

export default class ApprovePaymentDetailModal extends React.PureComponent{

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errorMessage: ''
    }
  }

  renderErrorMessage = () => {
    if(this.state.errorMessage!=='') {
      return (
        <Message
          error
          header='Error!'
          content={this.state.errorMessage}
        />
      )
    }
    else {
      return ''
    }
  }

  handleApproveClicked = () => {
    const params = {
      'service-payment': this.props.payment.id,
    }

    this.setState({
        isLoading: true,
        errorMessage: ''
    }, () => {
      GET('/common/admin-confirm-payment/', params).then(
        (payload) => {
          this.setState({
            isLoading: false,
            errorMessage: ''
          })
          this.props.onClose()
      },
      (payload) => {
        this.setState({
          isLoading: false,
          errorMessage: payload.errorMessages
        })
      })
    })
  }

  getCustomerName = () => {
    if(this.props.payment.customer) {
      return this.props.payment.customer.first_name + ' ' + this.props.payment.customer.last_name
    }
    else {
      return '-'
    }
  }

  getTechnicianName = () => {
    if(this.props.payment.technician) {
      return this.props.payment.technician.first_name + ' ' + this.props.payment.technician.last_name
    }
    else {
      return '-'
    }
  }

  getImages = () => {
      if(this.props.payment.images) {
        return this.props.payment.images.map((data) => {
          return (
              <Image 
                  key={data.id}
                  src={data.image}
              />
          );
        });
      }
      else {
        return ''
      }
  }

  render() {
    return(
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>รายละเอียดการชำระเงิน</Modal.Header>
        <Modal.Content>
          <Modal.Description>
          <List size='large' horizontal>
            <List.Item>
              <List.Content>
                <List.Header>Customer</List.Header>
                <List.Description>
                  {this.getCustomerName()}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>Technician</List.Header>
                <List.Description>
                  {this.getTechnicianName()}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>วันที่ upload เอกสาร</List.Header>
                <List.Description>
                  {this.props.payment.created}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>จำนวนเงิน (บาท)</List.Header>
                <List.Description>
                  {this.props.payment.amount}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>Service</List.Header>
                <List.Description>
                  {this.props.payment.service}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
          {this.getImages()}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {this.renderErrorMessage()}
          <Button primary onClick={this.handleApproveClicked} loading={this.state.isLoading}>
            ยืนยัน
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }  
}

ApprovePaymentDetailModal.defaultProps = {
  payment: {},
  open: false,
  onClose: ()=> {}
}

ApprovePaymentDetailModal.propTypes = {
  payment: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}